<template>
  <v-dialog
    v-model="status"
    width="400"
    persistent
  >
    <v-card>
      <v-card-title class="text-h6 grey lighten-2">
        <v-icon
          left
          color="warning"
        >
          {{ icons.mdiAlertCircle }}
        </v-icon>
        <span>Atenção</span>
      </v-card-title>

      <v-card-text>
        <p class="mb-2">
          Digite o texto abaixo para excluir o registro.
        </p>
        <code>{{ textToConfirm }}</code>

        <v-text-field
          v-model="text"
          class="mt-2"
          hide-details
          outlined
          dense
        />
      </v-card-text>

      <v-divider />

      <v-card-actions>
        <v-spacer />
        <v-btn
          color="secondary"
          outlined
          @click="$emit('close')"
        >
          Fechar
        </v-btn>
        <v-btn
          :disabled="textToConfirm != text"
          color="error"
          @click="$emit('action')"
        >
          Excluir
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import { mdiAlertCircle } from '@mdi/js'
import { ref } from '@vue/composition-api'

export default {
  props: {
    status: {
      default: true,
      type: Boolean,
    },
    textToConfirm: {
      default: 'Excluir',
      type: String,
    },
  },

  setup() {
    const text = ref(null)

    return {
      text,
      icons: {
        mdiAlertCircle,
      },
    }
  },
}
</script>
